/* visibility hidden added to uikit classes */
@media (min-width: $breakpoint-small) {
  .uk-hidden\@s { visibility: hidden !important; }
}
@media (min-width: $breakpoint-medium) {
  .uk-hidden\@m { visibility: hidden !important; }
}
@media (min-width: $breakpoint-large) {
  .uk-hidden\@l { visibility: hidden !important; }
}
@media (min-width: $breakpoint-xlarge) {
  .uk-hidden\@xl { visibility: hidden !important; }
}

/* visibility visible added to uikit classes */
@media (max-width: $breakpoint-xsmall-max) {
  .uk-visible\@s { visibility: visible !important; }
}
@media (max-width: $breakpoint-small-max) {
  .uk-visible\@m { visibility: visible !important; }
}
@media (max-width: $breakpoint-medium-max) {
  .uk-visible\@l { visibility: visible !important; }
}
@media (max-width: $breakpoint-large-max) {
  .uk-visible\@xl { visibility: visible !important; }
}
