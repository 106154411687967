/* dbq-admin-portal.scss */
.admin-portal {

  h1.admin-portal-heading {
    font-size: 26px;
    line-height: 26px;
    font-weight: normal;
    margin-top: 0px;
    color: $Black;
  }

  dbq-dropdown {
    justify-content: center;

    .dropTitle {
      // removed to allow for icon to be centered
      //width: 30px;
      //display: inline-block;
      //height: 100%;
      //margin-left: -2px;
      //margin-right: 0;

      text-align: center;

      display: flex;
      align-content: center;
      justify-content: center;
      width: 32px;
      height: 32px;

      i {
        right: unset;
      }

      svg.icon {
        height: 16px;
        min-height: 16px;
        width: 16px;
        min-width: 16px;
        margin-right: unset;
        margin-top: unset;
      }
    }

    &[active] {
      .dropTitle {
        background-color: $DashBBlue;
        color: $White;

        svg .icon-text {
          fill: $White;
        }
      }

      .dropMenu {
        min-width: 150px;
      }
    }

    .dropMenu {
      top: 30px;
      left: calc(50% - 17.5px);
      background-color: $DashBBlue;
      color: $White;

      a {
        color: $White;
        text-align: left;
        font-size: 12px;
        font-weight: normal;

        &:hover,
        &:focus {
          background: $u10;
          text-decoration: none;
        }
      }

      hr {
        margin: 5px 0;
      }
    }

    &.settings {
      display: inline-block;

      .dropMenu {
        top: 30px;
        left: 21px;
      }

      .dropTitle {
        width: auto;
        //padding-right: .5rem;
        padding: 0 10px;

        svg.icon {
          margin-right: 2px;
        }

        i {
          position: relative;
          top: unset;
          transform: unset;
          margin-right: 5px;
        }
      }
    }
  }

  .uk-width-1-5\@m {
    &.aps {
      @media (min-width: $breakpoint-medium) {
        padding-left: 0px;
      }
    }
  }
  .admin-portal-sidebar {
    background-color: $White;
    margin: 20px 0;
    padding: 0;
    border: 1px solid $ActiveDDNav;
    border-radius: 0px;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }

    .admin-portal-list {
      margin: 0;
      background-color: $White;

      h4 {
        font-weight: 400;
        margin: 0;
        padding: 8px;
        text-transform: uppercase;
        color: $Text;
        font-size: 12px;
        letter-spacing: 1px;
        background-color: $ActiveDDNav;

        svg.icon {
          margin-top: -4px;
        }
      }

      svg.icon {
        width: 16px;
        height: 16px;
        fill: $Text;
      }

      a.classLink {
        display: block;
        padding: 12px 16px;
        font-size: 14px;
        color: $Text;
        background-color: $White;
        font-weight: 400;
        margin-top: 0;
        opacity: .8;
        transition: .3s;
        border-top: 1px solid $Disabled;

        &:hover {
          background-color: $DashLtBlue;
          transition: .3s;
          text-decoration: none;
          opacity: 1;
        }

        &[active] {
          background-color: $DashLtBlue;
          color: $Text;
          opacity: 1;
        }
      }
    }

    .admin-portal-sidebar-box {
      margin-bottom: 0;
      background-color: $White;

      .add-class {
        margin: 0;
        padding: 16px;
        font-size: 1rem;
        border-top: 1px solid $Disabled;

        .uk-button-small {
          width: 125px;
          padding: 0 10px;
          line-height: 25px;
          font-size: 12px;
        }

        svg.icon {
          width: 14px;
          height: 14px;
          margin-right: 4px;
          fill: $Black;
        }
      }

      h4 {
        font-weight: 400;
        margin: 0;
        padding: 8px;
        text-transform: uppercase;
        color: $Text;
        font-size: 12px;
        letter-spacing: 1px;
        border-top: 1px solid $Disabled;
        background: $ActiveDDNav;

        svg.icon {
          width: 16px;
          height: 16px;
          margin-top: -4px;
          fill: $Text;
        }
      }

      a.commentLink,
      a.templateLink {
        display: block;
        padding: 12px 16px;
        font-size: 16px;
        color: $Text;
        background-color: $White;
        font-weight: 500;
        margin-top: 0;
        opacity: .7;
        transition: .3s;
        border-top: 1px solid $Disabled;

        &:hover {
          background-color: $DashLtBlue;
          transition: .3s;
          text-decoration: none;
          opacity: 1;
        }

        &[active] {
          background-color: $DashLtBlue;
          color: $Text;
          opacity: 1;
        }
      }

      .uk-button {
        text-align: left;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        &[active] {
          background-color: $Black;
        }
      }
    }
  }

  .admin-portal-content {
    margin: 0 auto;
    padding-top: 25px;

    @media (min-width: $breakpoint-medium) {
      padding: 25px 40px 25px 25px;
    }

    .admin-portal-content-header {
      margin-top: 3rem;

      .uk-button.uk-align-right {
        width: 160px;
      }
    }

    hr {
      border-top: 1px solid $Disabled;
    }

    h1 {
      font-size: 26px;
      line-height: 26px;
      font-weight: normal;
      margin-top: 0px;
      color: $Black;

      &.settings-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }



    h2 {
      font-size: 20px;
      line-height: 26px;
      font-weight: normal;
      color: $Black;

      &.asgn-to-copy {
        color: $DashBBlue;
      }
    }
  }

  .sticky {
    // below the navbars
    z-index: 3;
  }

  .mock-table-th {
    white-space: nowrap;
    font-size: 10px;
    font-weight: normal;
    color: $Text;
    background-color: $ActiveDDNav;
    padding: 8px 8px;
  }

  .admin-portal-table {
    th {
      white-space: nowrap;
      font-size: 10px;
      font-weight: normal;
      color: $Text;
      background-color: $ActiveDDNav;
    }

    td {
      font-size: 12px;
      font-weight: normal;
      border-bottom: 1px solid $ActiveDDNav;

      &.asgn-title {
        font-size: 14px;
      }

      .button-rubrics {
        &[selected] {
          outline: 4px solid $DashBGreen;
          outline-offset: -4px;
        }
      }

      // tighten buttons in grid //
      &.btn-tightener-right {
        padding-right: 5px;
      }
      &.btn-tightener-left {
        padding-left: 5px;
      }
    }

    tr.default-eb td {
      // bg color?
    }

    tr:last-child {
      td {
        border-bottom: 0px;
      }
    }

     // table inside modal
    .dbq-modal-body {
      margin-bottom: 0px;

      th.sticky {
        top: 0px;
      }
    }

    .dbq-modal-footer {
      margin-top: 0px;
    }
  }

  .admin-portal-dropdown {
    position: relative;
    overflow: visible;
    height: 100%;

    &[active=true] {
      .dropTitle {
        background-color: $DashBBlue;
        color: $White;

        &:hover,
        &:focus {
          background-color: $u10;
        }
      }
    }

    .dropTitle {
      padding: 0px;
      width: 30px;
      height: 30px;

      .uk-icon-cog {
        margin-top: 8px;
      }
    }
  }

  .admin-portal-scrollable-modal {
    position: relative;
    @media (max-width: 959px){
      height: 200px;
    }
    @media (min-width: 960px){
      height: 400px;
    }

    label.modal-rubric-select > input[type="checkbox"] {
      display: none;
    }
    label.modal-rubric-select > input[type="checkbox"] + div {
      cursor: pointer;
      display: inline-block;
      padding: 0px 12px;
      line-height: 30px;
      text-align: center;
      color: $Text;
      background-color: $Disabled;
      border: none;

      &:hover,
      &:focus {
        background-color: $ActiveDDNav;
      }
    }
    label.modal-rubric-select > input[type="checkbox"]:checked + div {
      outline: 4px solid $DashBGreen;
      outline-offset: -4px;
    }
  }

  .admin-portal-school-layout,
  .admin-portal-school-layout-form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 0px 8px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }

    .admin-portal-school {
      align-self: center;
      padding: 8px 8px;
      color: rgba($Text, .5);

      &.selected-school {
        color: $Text;
      }
    }
  }
  .admin-portal-school-layout-form {
    max-height: 500px;

    @media (max-width: 767px) {
      max-height: 300px;
    }

    .admin-portal-school {
      color: $Text;
      position: relative;
    }
  }

  .admin-portal-assignments-edit,
  .admin-portal-rubrics-creation {
    h1 {
      input {
        height: unset;
        font-size: 26px;
        line-height: normal;
        color: $DashBBlue;
        border: 1px solid $DashBBlue;
        padding: 3px 6px;

        &:focus {
          border: 1px solid $DashBBlue;
          background-color: rgba($DashBBlue, .12);
        }
      }
    }
  }

  .admin-portal-assignments-edit {
    label > input[type="checkbox"] {
      display: none;
    }
    label > input[type="checkbox"] + *::before {
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      border: 2px solid $DisabledTab;
      flex-shrink: 0;
    }
    label > input[type="checkbox"]:hover + *::before,
    label > input[type="checkbox"]:focus + *::before {
      background-color: $DashMdBlue;
      border: 2px solid $DashMdBlue;
    }
    label > input[type="checkbox"]:checked + * {
      color: $DashBBlue;
    }
    label > input[type="checkbox"]:checked + *::before {
      // checkmark NOT in wireframe
      // content: "✓";
      color: $White;
      text-align: center;
      background-color: $DashBBlue;
      border: 2px solid $DashBBlue;
      display: inherit;
    }

    label > input[type="checkbox"] + * {
      display: inline-flex;
      cursor: pointer;
    }

    label span {
      line-height: 20px;
    }
  }

  .admin-portal-rubrics-creation {
    .rubrics-creation-label {
      margin-top: 0px;
      margin-bottom: 0px;

      // @media (min-width: 960px){
      @media (min-width: $breakpoint-medium) {
        text-align: right;
      }
    }

    input[type="text"],
    input[type="number"] {
      border: 1px solid $DashBBlue;
      padding: 3px 6px;

      &:focus {
        color: $DashBBlue;
      }
    }

    label > input[type="radio"] {
      display: none;
    }
    label > input[type="radio"] + div {
      cursor: pointer;
      display: inline-block;
      min-width: 40px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      color: $DashBBlue;
      background-color: $White;
      border: 1px solid $DashBBlue;
      padding: 0px 6px;

      &:hover,
      &:focus {
        color: $Text;
        background-color: $DashMdBlue;
        border: 1px solid $DashMdBlue;
      }
    }
    label > input[type="radio"]:checked + div {
      color: $White;
      background-color: $DashBBlue;
    }

    .include-scores-only[disabled] {
      opacity: .25;
      pointer-events: none;
    }

    textarea {
      width: 100%;
      display: block;
      margin-top: .25rem;
      border: 1px solid $DashBBlue;

      &:focus {
        color: $DashBBlue;
      }
    }
  }

  .has-Bindermessage-col {
    .binderWrap {
      @media (min-width: 768px){
        width: 33.333%;
      }

      &.binderMissing {
        cursor: not-allowed;

        .binder {
          pointer-events: none;
          position: relative;
          opacity: .5;
          // border: 4px solid $Black;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($DisabledTab, .5);
          }
        }
      }
    }
  }

  .binderMessage-col {
    @media (min-width: 960px){
      order: 2;
    }

    .binderMessage,
    .binderMessage-box {
      margin-top: 15px;
    }

    .binderMessage-box {
      display: flex;
      flex-direction: column;
      border: 1px solid $Red;

      h4 {
        color: $White;
        background-color: $Red;
        padding: 12px;
      }

      ul {
        padding-right: 12px;
      }
    }
  }

  .admin-portal-scrollable-modal,
  .admin-portal-scroll,
  .admin-portal-school-layout-form,
  .rubric-wizard,
  .rubric-wizard textarea,
  .rubric-wizard .ql-editor {
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      border-left: 1px solid $Disabled;
    }

    &::-webkit-scrollbar:vertical {
      width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($Text, .5);
    }
  }

  .admin-portal-table {
    .actions-column {
      display: flex;
      justify-content: space-between;

      .uk-button {
        margin-right: 6px;

        &:last-child {
          margin-right: 0px;
        }

        svg.icon {
          min-width: 16px;
          min-height: 16px;

          use.icon-white {
            fill: $White;
          }
          use.icon-text {
            fill: $Text;
          }
        }
      }
    }
  }
  .uk-subnav-pill  {
    margin-left: 0px;
    margin-bottom: 0px;

    li {
      padding-left: 0px;

      &:first-child {
        padding-right: 6px;
      }

      a {
        color: $Text;
        text-transform: none;
        padding: 6px 12px;
        border: 1px solid $ActiveDDNav;
        border-bottom: 0px;
      }

      &.uk-active > a {
        font-weight: bold;
        color: $Text;
        background-color: $ActiveDDNav;
      }
    }
  }
}

// weird margin-left issue //
dbq-yes-no-input {
  &.dyni-offset {
    margin-left: 1px;
  }
  a.uk-button-default.dyni-offset-btn-preview {
    border-color: transparent !important;
    border: 0px !important;
    border-left: 5px $White solid !important;
  }
}


// apply new UIKIT styling via @extend
.ql-editor {
  @extend .uk-textarea;
  border: 0px;
}


// add custom binder
.dbq-box {
  padding: 15px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
}
.custom-binder-color,
.custom-binder-icon {
  label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 6px 18px 6px 0;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &[value="RED"] ~ .color {
        background-color: $CustomBinderRed;
      }
      &[value="ORANGE"] ~ .color {
        background-color: $CustomBinderOrange;
      }
      &[value="YELLOW"] ~ .color {
        background-color: $CustomBinderYellow;
      }
      &[value="GREEN"] ~ .color {
        background-color: $CustomBinderGreen;
      }
      &[value="TEAL"] ~ .color {
        background-color: $CustomBinderTeal;
      }
      &[value="BLUE"] ~ .color {
        background-color: $CustomBinderBlue;
      }
      &[value="PURPLE"] ~ .color {
        background-color: $CustomBinderPurple;
      }
    }

    .color,
    svg {
      width: 30px;
      height: 30px;
      padding: 2px;
      border: 1px solid $Black;
      border-radius: 50%;
      outline: 3px solid $White;
      outline-offset: 0;
      box-sizing: border-box;
    }
    svg {
      fill: $Black;
    }

    &:hover,
    &:focus {
      .color,
      svg {
        border-color: $DashBBlue;
      }
      svg {
        fill: $DashBBlue;
      }
    }

    input:checked ~ {
      .color,
      svg {
        border-color: $Black;
        outline-color: $Black;
      }
      svg {
        fill: $Black;
      }
    }
  }
}

.table-sortable {
  tr {
    background-color: $White;

    &.sortable-chosen {
      opacity: 1 !important;
      background-color: $White;
    }
    &.sortable-ghost {
      opacity: 1 !important;
      background-color: $DashLtBlue;
      transition: unset;
    }
  }

  .sortable-unit {
    width: 25px;
    min-width: 25px;
    max-width: 25px;
    vertical-align: middle !important;

    .handle {
      cursor: move;
      width: 25px;
      height: 25px;
    }
  }
}

.no-conflict-spacer {
  display: inline-block;
  width: 18px;
  height: 18px;
}
svg.icon-conflicts {
  margin-top: -3px;
  width: 18px;
  height: 18px;

  use.icon-white {
    fill: $White;
  }
  use.icon-text {
    fill: $DisabledTab;
  }
}


.admin-portal.uk-modal {
  &.uk-open.modal-flex {
      display: flex !important;
      align-items: center;
      justify-content: center;
  }

  .uk-modal-dialog {
    width: 800px;
    max-width: 800px;
  }

  .admin-portal-table {
    .admin-portal-scrollable-modal {
      .sticky {
        top: 0;
      }
    }
  }

  .uk-modal-footer {
    display: flex;
    justify-content: flex-end;

    .uk-modal-close + button {
      margin-left: 15px;
    }

    button {
      margin-top: 0 !important;
    }
  }
}
