@import 'vendor-components';
@import 'colors';
@import 'fonts';
@import 'uk-overwrites';
@import 'aside-accordions';
@import 'dash';
@import 'main-content';
@import 'buttons';
@import 'form-errors';
@import 'binder';
@import 'footer';
@import 'top-nav';
@import 'pd-videos';
@import 'dbq-table-grid';
@import 'dbq-inst-manage';
@import 'dbq-modal';
@import 'dbq-dropdown.scss';
@import 'dbq-switcher.scss';
@import 'dbq-admin-portal';
@import 'dbq-admin-rubric-wizard';
@import 'dbq-simple-quill.scss';
@import 'uk-visibility-update';

@media (prefers-reduced-motion: reduce) {
  *{
    transition-duration: .01s !important;
  }
}
[hidden]{
  display: none !important;
}
// stick the footer to bottom on short content pages
.sticky-footer {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;

  .main-content {
    flex: 1;
    width: 100%;
    display: inline-block;
  }
}


/*! DBQOL Online Library */
.DBQOL {
  padding: 15px;
  background: $White;
  border: 1px solid $Disabled;
  border-radius: 4px;

  h3 {
    line-height: 20px;
  }

  .uk-grid-margin {
    margin-top: 10px;
  }
}

.uk-card {
  // background-color: $ActiveDDNav;

  .DBQbg {
    padding: 5px;
  }

  .docAnalPreviews,
  .rubricsPreviews,
  .CustomPage,
  .ChatPage {
    img {
      cursor: pointer;
    }
  }
  .Previews {
    cursor: pointer;
  }

  &.rubricsPreviews,
  &.docAnalPreviews,
  &.CustomPage,
  &.ChatPage {
    text-align: center;
    background-color: $White;

    h6 {
      text-transform: capitalize;
    }

    img {
      border: 4px solid $Disabled;
    }

    &:hover {
      img {
        border: 4px solid $wcagAAA-dashBGreen-hover;
      }
    }

    &.SelectedPreview,
    &.PageIncluded,
    &[selected] {
      img {
        border: 4px solid $DashBGreen;
      }
    }
  }

  .ChatPage {
    .chatPageSelected-wrapper {
      position: relative;

      svg {
        display: none;
      }
    }

    &[selected] {
      .chatPageSelected-wrapper {
        svg {
          cursor: pointer;
          display: inline;
          width: 60px;
          height: 60px;
          fill: $DashBGreen;
        }
      }
    }
  }

  .DA_sheet_page {
    margin: 5px 0;
  }

  .EVAssignment {
    color: $White;
    background: $GreyDark;
    cursor: pointer;

    .SelectedType {
      &:hover,
      &:focus {
        background: $DashBGreen;
      }
    }
  }

  .uk-form {
    .uk-button-primary {
      color: #fff;
    }
  }
}

.container-secondarynav .secondarynav-dropDown,
.topNav-dropDown,
.topNav-dropDownRight {
  @extend .uk-navbar-nav;
  display: flex;
  // float: none;
  // padding: 0!important;
  align-items: center;
  // max-width: 127px;
  li {
    float: none;
  }

  ul {
    @extend .uk-dropdown-nav;
    @extend .uk-nav;
    @extend .uk-navbar;
  }

}

.EVTabs {
  cursor: pointer;
}

a {
  &.EVAssignment {
    height: 60px;
    font-size: 18px;
    line-height: 60px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }
}

.uk-active {
  .EVTabs {
    a{
      &.EVAssignment {
        color: $White;
        background: $DashBGreen;

        &:hover,
        &:focus {
          background: $TabActiveHover;
        }
      }
    }
  }
}

.uk-disabled {
  .EVTabs {
    a {
      &.EVAssignment {
        cursor: default !important;

        &:hover,
        &:focus {
          color: $DisabledTab;
          background: $Disabled;
        }
      }
    }
  }
}

.button-selected,
.button-selected:hover,
.button-selected:focus{
  color: $White;
  background: $DashBGreen;
}

#langul.button-selected {
  background: $White;
}

.bgLogin {
  @extend .uk-flex-middle;
  @extend .uk-text-center;
  @extend .uk-height-1-1;
  @extend .uk-background-cover;

  @media only screen and (max-width: 767px){
    min-height: 1200px;
  }

  &.bgImg0 {
    background-image: url('../img/login-background-0.jpg');
  }
  &.bgImg1 {
    background-image: url('../img/login-background-1.jpg');
  }
  &.bgImg2 {
    background-image: url('../img/login-background-2.jpg');
  }
  &.bgImg3 {
    background-image: url('../img/login-background-3.jpg');
  }
  @media (max-width: 375px){
    &.bgImg0 {
      background-image: url('../img/login-background-0-375.jpg');
    }
    &.bgImg1 {
      background-image: url('../img/login-background-1-375.jpg');
    }
    &.bgImg2 {
      background-image: url('../img/login-background-2-375.jpg');
    }
    &.bgImg3 {
      background-image: url('../img/login-background-3-375.jpg');
    }
  }
  @media (max-width: 767px){
    &.bgImg0 {
      background-image: url('../img/login-background-0-767.jpg');
    }
    &.bgImg1 {
      background-image: url('../img/login-background-1-767.jpg');
    }
    &.bgImg2 {
      background-image: url('../img/login-background-2-767.jpg');
    }
    &.bgImg3 {
      background-image: url('../img/login-background-3-767.jpg');
    }
  }
  @media (max-width: 1200px){
    &.bgImg0 {
      background-image: url('../img/login-background-0-1200.jpg');
    }
    &.bgImg1 {
      background-image: url('../img/login-background-1-1200.jpg');
    }
    &.bgImg2 {
      background-image: url('../img/login-background-2-1200.jpg');
    }
    &.bgImg3 {
      background-image: url('../img/login-background-3-1200.jpg');
    }
  }
}

.loginSupport {
    color: $White !important;
    background: $Black;
}

.sub-title {
  color: $DashBBlue;
}

.dotted {
  border-top: 1px dotted $GreyLite;
}

/*! Input */
.form-assignTitle {
  width: 75%;
  margin-left: 5px;
  padding: 5px;

  @media only screen and (max-width: 667px){
    width: 97%;
    margin-left: 0;
  }
}

.input-classLink {
  width: 96%;
  padding: 5px;
}

/*! Print CSS adds to uikit.css */
@media print {
  .uk-navbar,
  .container-SecondaryNav {
    display: none;
  }
}

.hide-class.show {
  color: $DBQ;
  font-weight: bold;
}

.hide-assign{
  background-color: $Disabled;

  &:hover,
  &:focus {
    color: $Text;
    background-color: $ActiveDDNav;
  }
  &.on,
  &.off {
    width: 36px;
    padding: 0;
    font-size: 11px;

    &.active {
      cursor: default;
    }
  }

  &.on.active,
  &.on.active:hover {
    background-color: $DashBGreen;
    color: $White;
  }

  &.off.active,
  &.off.active:hover {
    background-color: $DashBGreen;
    color: $White;
  }
}


// Login
.bgLogin .uk-animation-slide-bottom {
  margin-top: 50px;
  animation-duration: .8s !important;

  @media (max-width: 666px) {
    margin-top: 0;
  }
}
.login-boundingBox {
  padding: 20px 30px;
  background: rgba(255, 255, 255, 0.5);

  .uk-card-default {
    .uk-card-body {
      border-bottom: 0;

      // hard-coded heights replaced with attrubute uk-height-match="target: > div > .uk-card > .uk-card-body"
    }

    .uk-card-footer {
      border-top: 0;
    }
  }

  button[type="submit"] {
    margin-top: 0 !important;
  }
  button,
  .uk-button {
    font-size: 16px;
  }
}

.loginLinks {
  a {
    color: $DashBBlue;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.loginLogos {
  @media only screen and (min-width: 641px) and (max-width: 960px){
    max-width: 60%;
  }
}

// custom button icons
h2,
.uk-button {
  svg.icon {
      height: 16px;
      min-height: 16px;
      width: 16px;
      min-width: 16px;
      margin-right: 2px;
      margin-top: -2px;
  }
  use.icon-white {
      fill: $White;
  }
  use.icon-text {
    fill: $Text;
  }
  svg path {
      fill: inherit;
  }
  &.uk-button-small {
    svg.icon {
      width: 14px;
      height: 14px;
      margin-top: 0;
    }
  }
}


a.blue-link {
  color: $DashBBlue;
  text-decoration: underline;
  &:hover {
      color: $Text;
  }
  &:focus {
    outline: none !important;
    background-color: $DashBBlueHover !important;
    color: $White;
  }
}

.orange-text {
  color: $DashBOrange;
}

.note-alert {
  background-color: rgba($DashLtBlue, 0.75);
  padding: 10px 16px;
}

.dashAssign-message {
  padding: 0 6px;
  opacity: 1;
  height: auto;
  max-height: 200px;
  transition-property: opacity, max-height;
  transition-duration: 1s;
  transition-timing-function: ease-out;
  display: block !important;
  overflow: hidden;

  &[hidden] {
    opacity: 0;
    max-height: 0;
    display: block !important;

  }
}

.saveWarning {
  padding: 20px;
}

.dash-image {
  margin-top: -100px;
  max-height: 520px;
  margin-left: 30px;

  @media (max-width: 1219px){
    margin-top: 0;
    max-height: 436px;
  }
  @media (max-width: 959px){
    margin: 0 auto;
    display: inherit;
  }
}

.dash-joinclass {
  background-color: rgba($DashLtBlue, 0.5);

  input {
    margin: 0 5px;
    text-align: center;
    font-size: 28px;
    border: solid 1px $GreyLite;
    box-shadow: 0 0 5px $GreyLite inset;
    outline: none;
    transition: all .2s ease-in-out;
    border-radius: 3px;
    height: 45px !important;
    width: 45px !important;
    text-transform: uppercase;

    &:focus {
      border-color: $DashBOrange;
      box-shadow: 0 0 5px $DashBOrange inset;
    }

    &::selection {
      background: transparent;
    }

    &::placeholder {
      color: $Disabled;
    }
  }

  button.disabled {
    color: lighten($button-primary-hover-color, 25%) !important;
    background-color: $GreyLite;
    cursor: not-allowed;
    &:active {
      pointer-events: none;
    }
  }
  button:not(.disabled) {
    background-color: $DashBBlue;

    &:hover, &:focus {
      background-color: $DashBBlueHover;
    }
  }

  .errorlist {
    border: 1px solid $DBQ;
    background-color: $FadedRed;
    color: $DBQ;
    list-style: none;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 4px;
    margin: 20px 0;

  }
}

dbq-tabs {
  nav {
    max-height: 3000px;
    opacity: 1;
    transition-property: opacity, max-height;
    transition-duration: .4s;
    transition-timing-function: ease-out;
  }

  &[slide-up] nav{
    max-height: 0;
    opacity: 1;
  }
}

#sampleChooser {
  h5 {
    word-wrap: break-word;
  }
}

// shared assignments
.bgShared {
  @extend .uk-flex;
  @extend .uk-flex-middle;
  @extend .uk-text-center;
  @extend .uk-height-1-1;
  @extend .uk-background-cover;

  background-image: url('../img/shared-background.jpg');

  @media (max-width: 375px){
    background-image: url('../img/shared-background-375.jpg');
  }
  @media (max-width: 767px){
    min-height: 1200px;
    background-image: url('../img/shared-background-767.jpg');
  }
  @media (max-width: 1200px){
    background-image: url('../img/shared-background-1200.jpg');
  }

  .shared-boundingBox {
    padding: 20px 30px;
    background: rgba(255, 255, 255, 0.5);

    .uk-card-body {
      padding: 30px 30px;
    }

    .icon {
      width: 40px;
      height: 28px;
    }
  }
}

.dbq-responsive-mt {
  @media only screen and (min-width: 641px){
    margin-top: 6px;
  }
}

// custom error pages
.bgErrorPg {
  @extend .uk-flex-middle;
  @extend .uk-height-1-1;
  @extend .uk-background-contain;
  @extend .uk-background-bottom-left;
  background-image: url('../img/error-404-noaccess.jpg');

  @media only screen and (max-width: 639px){
    background-image: url('../img/error-404-noaccess-sm.jpg');
  }
  @media only screen and (max-width: 959px){
    min-height: 650px;
  }
  @media only screen and (min-width: 960px) and (max-width: 1199px){
    min-height: 800px;
  }
}

.class-code {
  label {
    font-size: 12px;
    display: inline-grid;
    justify-items: center;
  }
}
