dbq-dropdown, .dbq-dropdown {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  margin-left: auto;
  position: relative;
  overflow: visible;
  height: 100%;
  font-size: 14px;
  line-height: 20px;

  &.settings {
    margin-left: 0px;
    align-self: flex-start;
  }

  &[active] .dropMenu {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    z-index: 1000;
    min-width: 200px;
    transform: scale(1, 1);
    overflow-y: auto; // 'scroll' changed to 'auto' so that its only visible when necessary
    max-height: calc(100vh - 120px);
  }

  &.secondarynav-dropDown[active] .dropMenu {
    min-width: 150px;

    a {
      padding: 5px 10px;
      line-height: normal;
    }
  }
}

.dropTitle {
  height: 100%;
  padding: 0 15px;
  //padding-right: 20px;
  margin-right: 5px;
  box-sizing: border-box;
  position: relative;
  color: $Text;
  background-color: inherit;
  display: flex;
  align-content: center;
  flex-wrap: nowrap;
  z-index: 1;
  align-items: center;

  i {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  dbq-dropdown.topNav-dropDownRight & {
    color: $White;

    &:hover,
    &:focus {
      color: $White;
      background-color: lighten($Black, 20%);
      text-decoration: none;
    }
  }

  dbq-dropdown[active].topNav-dropDownRight & {
    color: $White !important;
    background-color: lighten($Black, 20%);
  }

  sw-annotation-nav  dbq-dropdown[active] & {
    color: $Text;
    background-color: $ActiveDDNav;
  }
}

.dropMenu {
  // z-index: -1;
  display: block;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 40px;
  left: 0;
  background: $ActiveDDNav;
  color: $Text;
  width: auto;
  box-sizing: border-box;
  transform: scale(1, 0);
  transform-origin: top left;
  transition: transform .4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  justify-content: flex-start;

  font-size: 14px;
  line-height: 20px;

  a {
    display: block;
    padding: 5px 15px;
    color: $Text;
    box-sizing: border-box;
  }

  a:hover,
  a:focus {
    background: $u10;
    color: $White;
  }

  .topNav-dropDownRight & {
    top: 50px;
    left: unset;
    right: 5px;
    background: lighten($Black, 20%);

    .uk-nav-header {
      // color: rgba($White,.33);
      color: rgba($White,.60);
      margin-top: 15px;

      &:first-child {
        margin-top: 0px;
      }
    }

    a {
      // color: rgba($White,.75);
      color: $White;
    }

    a:hover,
    a:focus {
      color: $White;
    }
  }

  sw-annotation-nav & {
    top: 40px;

    a {
      padding: 10px;
    }

    hr {
      margin: 5px 0;
    }
  }
}



.hideCanvasFreeHand {

  .dropItem[data-toggle="FreeHand"] {
    .annotation-toggleOn {display: none;}
    .annotation-toggleOff {display: inline-block;}
  }
}

.hideCanvasFeedback {

  .dropItem[data-toggle="Feedback"] {
    .annotation-toggleOn {display: none;}
    .annotation-toggleOff {display: inline-block;}
  }
}

.hideCanvasAnnotation {

  .dropItem[data-toggle="Annotation"] {
    .annotation-toggleOn {display: none;}
    .annotation-toggleOff {display: inline-block;}
  }
}

.hideCanvasTeacher {

  .dropItem[data-toggle="Teacher"] {
    .annotation-toggleOn {display: none;}
    .annotation-toggleOff {display: inline-block;}
  }
}




/* the toggles in the annotation nav to turn on an
   off the visibility of the 3 different annotation canvas types */
.annotation-toggleOn,
.annotation-toggleOff {
  display: inline-block;
  width: 38px;
  text-align: center;
  font-size: 12px;
  margin-right: 5px;
  padding: 4px 0;
  color: $White;
  background-color: $Green;
}

.annotation-toggleOff {
  background-color: $DBQ;
}

.dropItem {
  width: 100%;
  display: block;

  &:hover {
    text-decoration: none;

    .annotation-toggleOn,
    .annotation-toggleOff {
      color: $ebNav;
      background-color: $White;
    }
  }
  .secondarynav-variablelink &.current {
    color: $White;
    background-color: $DBQ;
  }
}
