dbq-inst-manage,
dbq-shared-manage,
dbq-rubrics-shared-manage {
  display: block;
  opacity: 1;
  transition: opacity .4s linear;

  &[should-fade] {
    opacity: 0;
  }

  [fake],
  [fake] .uk-text-danger {
    color: $Disabled !important;
    background-color: $Disabled;

    header {
      color: $Disabled !important;
      background-color: $Disabled;
    }

    button,
    a,
    svg,
    .activity-icon,
    .no-students,
    .no-secondary-teacher {
      display: none;
    }
  }

  > div {
    max-height: 3000px;
    opacity: 1;
    transition-property: opacity, max-height;
    transition-duration: .4s;
    transition-timing-function: ease-out;

    &[slide-up] {
      max-height: 0;
      opacity: 1;
      overflow: hidden;
      border: 0;
    }
  }

  h2 {
    svg.icon {
      width: 20px;
      height: 20px;
    }
  }

  nav {
    display: block;
    width: 100%;
  }

  article {
    display: block;
    position: relative;
    overflow: hidden;
  }

  .class-directions {
    font-size: 12px;
    line-height: 18px;
    padding: 20px 10px;
    border-bottom: 1px solid $GreyLite;
    margin-bottom: 30px;
    transition-property: opacity, max-height, padding, margin-bottom;

    &[slide-up] {
      padding: 0;
      margin-bottom: 0;
      overflow: hidden;
    }
  }

  dbq-dropdown {
    &.class-settings {
      height: auto;

      .dropTitle {
        padding: 0 10px;
      }

      .dropMenu {
        top: 25px;
      }
    }
  }

  dbq-modal {
    span {
      width: 100%;
    }

    .dbq-modal-footer {
      text-align: right;
      border-top: 1px solid #ddd;
      padding-top: 1em;
      margin: 0;
      justify-content: flex-end;

      a.uk-button {
        margin-left: 10px;
      }
    }
  }

  dbq-tabs {
    a.uk-button[active] {
      color: $Text;
      background-color: $ActiveDDNav;
      border: 1px solid $ActiveDDNav;
      font-weight: bold;
    }

    a.uk-button:not([active]) {
      background-color: $White;
      border: 1px solid $Disabled;
      border-bottom: 1px solid $ActiveDDNav;

      &:hover {
        color: $Text;
        background-color: $Disabled;
      }
    }
  }
}

// instructor classes sidebar
.inst-assignments {
  .uk-width-1-5\@m {
    @media (min-width: $breakpoint-medium) {
      padding-left: 0px;
    }
  }
  .inst-classes-sb {
    background-color: $White;
    margin-top: 20px;
    padding: 0;

    a.add-class {
      margin-top: 5px;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
    }

    .inst-class-list {
      margin: 0;
      background-color: $White;

      h4 {
        font-weight: 500;
        margin: 0;
        padding: 8px;
        text-transform: uppercase;
        color: $GreyDark;
        font-size: 12px;
        letter-spacing: 1px;
        background-color: $ActiveDDNav;

        svg.icon {
          width: 16px;
          height: 16px;
          margin-top: -4px;
          fill: $GreyDark;
        }
      }

      a.classLink {
        display: block;
        padding: 12px 16px;
        font-size: 16px;
        color: $Text;
        background-color: $White;
        font-weight: 500;
        margin-top: 0;
        opacity: .8;
        transition: .3s;
        border-top: 1px solid $Disabled;

        &:hover {
          background-color: $DashLtBlue;
          transition: .3s;
          text-decoration: none;
          opacity: 1;
        }

        &[active] {
          background-color: $DashLtBlue;
          color: $Text;
          opacity: 1;
        }
      }
    }

    .inst-class-scroll {
      max-height: 180px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        border-left: 1px solid $Disabled;
      }

      &::-webkit-scrollbar:vertical {
        width: 11px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 11px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        background-color: rgba(0, 0, 0, .5);
      }
    }

    .inst-classes-sb-box {
      margin-bottom: 0;
      background-color: $White;

      .add-class {
        margin: 0;
        padding: 16px;
        font-size: 1rem;
        border-top: 1px solid $Disabled;
      }

      h4 {
        font-weight: 500;
        margin: 0;
        padding: 8px;
        text-transform: uppercase;
        color: $GreyDark;
        font-size: 12px;
        letter-spacing: 1px;
        border-top: 1px solid $Disabled;
        background: $ActiveDDNav;

        svg.icon {
          width: 16px;
          height: 16px;
          margin-top: -4px;
          fill: $GreyDark;
        }
      }

      a.commentLink,
      a.templateLink {
        display: block;
        padding: 12px 16px;
        font-size: 16px;
        color: $Text;
        background-color: $White;
        font-weight: 500;
        margin-top: 0;
        opacity: .7;
        transition: .3s;
        border-top: 1px solid $Disabled;

        &:hover {
          background-color: $DashLtBlue;
          transition: .3s;
          text-decoration: none;
          opacity: 1;
        }

        &[active] {
          background-color: $DashLtBlue;
          color: $Text;
          opacity: 1;
        }
      }

      .uk-button {
        // width: 100%;
        text-align: left;
        margin-bottom: 10px;

        svg.icon {
          margin-top: 0;

          use {
            fill: $GreyDark;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &[active] {
          background-color: $Black;
        }
      }
    }
  }

  // instructor manager container
  .inst-manage-cont {
    margin: 0 auto;
    padding-top: 25px;

    @media (min-width: $breakpoint-medium) {
      padding: 25px 40px 25px 25px;
    }
  }
}

@media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) {
  .truncate {
    display: none;
  }
}


.feedback-info {
  border: 1px solid $GreyLite;
  border-radius: 4px;
  margin: 40px 10px 20px 10px;

  .uk-grid {
    align-items: center;

    h3 {
      font-size: 16px;
      margin: 10px 0;
      padding: 0px 10px;
    }

    p {
      margin: 0;
      padding: 0px 10px;
      font-size: 12px;
    }

    button {
      margin: 10px auto;
      //width: 90%;
      display: block;
    }
  }

  img {
    border-bottom: 1px solid $GreyLite;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  @media (max-width: $breakpoint-large) {
    max-width: 200px;
    margin-bottom: 40px !important;
  }
}
