
// Custom variables and variable overwrites
$button-padding-horizontal: 12px;
$button-line-height: 30px;

$button-default-background: #eee;
$button-default-hover-background: #f5f5f5;
$button-default-active-background: #ddd;

// WCAG AAA compliant colors
$wcagAAA-dashBBlue: #0057ad;
$DashBBlue: $wcagAAA-dashBBlue;


$button-primary-background: $wcagAAA-dashBBlue;
$button-primary-hover-background: #35b3ee;
$button-primary-active-background: #0091ca;
$button-primary-hover-color: #1c1c1c;

// not sure if these declarations are needed yet
//$badge-background: $wcagAAA-dashBBlue;
//$block-primary-background: $wcagAAA-dashBBlue;
//$contrast-button-primary-background: $wcagAAA-dashBBlue;
//$datepicker-table-active-background: $wcagAAA-dashBBlue;
//$form-advanced-checked-color: $wcagAAA-dashBBlue;
//$nav-autocomplete-active-background: $wcagAAA-dashBBlue;
//$nav-dropdown-hover-background: $wcagAAA-dashBBlue;
//$nav-navbar-hover-background: $wcagAAA-dashBBlue;
//$nav-search-active-background: $wcagAAA-dashBBlue;
//$nav-side-active-background: $wcagAAA-dashBBlue;
//$pagination-active-background: $wcagAAA-dashBBlue;
//$progress-bar-background: $wcagAAA-dashBBlue;
//$subnav-pill-active-background: $wcagAAA-dashBBlue;

$card-body-padding-vertical: 15px;
$card-body-padding-vertical-l: 15px;
$card-body-padding-horizontal: 15px;
$card-body-padding-horizontal-l: 15px;

$form-radio-checked-background: #00a8e6;

$modal-dialog-width: 600px;

$nav-header-padding-horizontal: 15px;

// Default variables and available mixins
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";

@import "~uikit/src/scss/uikit-theme.scss";
// @import 'components/uikit/components/datepicker.scss';
@import 'colors.scss';

// Custom mixin overwrites.
// for example: @import 'padding-mixin.scss';
