
body {
  // margin-tops have been increased by 10 for new topNav height
  margin-top: 60px;

  &.withSecondaryNav {
    margin-top: 102px;

    .uk-modal-page & {
      margin-top: 77px;
    }
  }

  &.withTertiaryNav,
  &.preview-mode {
    margin-top: 142px;

    // start: styles for side-by-side
    overflow-x: auto;

    .container-wrapper {
      margin-right: auto;
      margin-left: 175px;

      // john's DBQ-1532 experimentation
      //@media only screen and (max-width: 961px) {
      //  margin-left: 110px;
      //}
    }

    // end: styles for side-by-side

    .uk-modal-page & {
      margin-top: 142px;
    }
  }

  &.withFourthNav {
    margin-top: 192px;

    .uk-modal-page & {
      margin-top: 167px;
    }
  }

  &.body-login {
    @extend .uk-height-1-1;
    margin-top: 0;
  }

  @media (max-width: 666px) {
    margin-top: 0 !important;
  }
}


.topNav-login,
.topNav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: block;
  width: 100%;
  height: 50px;
  font-family: $base-body-font-family;
  box-sizing: border-box;

  @media (max-width: 666px) {
    position: relative;
    top: unset;
    left: unset;
    height: auto;
  }

  nav {
    display: flex;
    flex-wrap: nowrap;
    flex-basis: 100%;
    flex-direction: row;
    width: 100%;
    background-color: $Black;
    height: 50px;
    box-sizing: border-box;

    &.annotation {
      overflow: visible;
    }
  }

  nav > a,
  nav .uk-navbar-nav > li > a:not(.brand),
  nav .uk-navbar-item {
    display: inline-flex;
    height: 100%;
    min-height: unset;
    align-items: center;
    padding: 0 15px;
    // previous uk-kit version declarations
    font-size: 14px;
    line-height: 20px;

    @media (max-width: 639px) {
      font-size: 12px;
      line-height: 18px;
      padding: 0 12px;
    }
  }

  nav > a,
  nav .uk-navbar-nav > li > a:not(.brand) {
    color: $White;
    position: relative;

    &:disabled,
    &:disabled:hover,
    &:disabled:focus {
      background: $Disabled;
    }

    &:hover,
    &:focus {
      color: $White;
      background-color: lighten($Black, 20%);
      outline: none;
      text-decoration: none;
    }

    //&:focus {
    //  outline: 2px dotted #333;
    //  outline-offset: 1px;
    //}

    &.active {
      color: rgba($White, .75) !important;

      span.nav-text {
        border-bottom: 2px solid rgba($White, .75);
        padding-bottom: 4px;
        margin-top: 5px;
      }
    }
  }

  nav .uk-navbar-nav > li.logout {
    align-self: flex-end;
    margin-left: auto;
  }

  nav .uk-navbar-item.topNav-variablelink {
    width: 33%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba($White, .75) !important;
    background-color: $Black !important;
    justify-content: start;

    em, i,
    b, strong {
      color: unset;
    }
  }

  .topNav-dropDown,
  .secondarynav-dropDown,
  .topNav-dropDownRight {
    display: inline-flex;
    height: 100%;
    color: $White;
    align-items: center;
    max-width: 400px;
    position: relative;

    .dropTitle {
      font-size: 14px;
      line-height: 20px;

      svg.icon {
        height: 16px;
        min-height: 16px;
        max-width: 16px;
        width: 16px;
        min-width: 16px;
        fill: $White;

        &.icon-sm-gap-end {
          @media (min-width: 641px) {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .topNav-dropDownRight {
    li > a {
      display: inline-flex;
      color: $White;
      align-items: center;
    }

    ul.uk-dropdown {
      background-color: lighten($Black, 20%);

      li > a {
        display: block;
        height: 30px;
        padding: 10px 15px 0;
      }
    }

    .uk-nav-divider {
      margin: 5px 0;
      border-top: 1px solid rgba($White, .15);
    }
  }

  .topNav-dropDownRight > li:hover > a,
  .topNav-dropDownRight > li:focus > a,
  .topNav-dropDownRight > li:active > a {
    color: $White;
    background-color: lighten($Black, 20%);
  }

  .topNav-dropDownRight > li.uk-open > a {
    color: $White;
    background-color: lighten($Black, 20%);
  }
}

.secondarynav-dropDown {
  max-width: calc(60% - 259px);

  &.secondarynav-variablelink {
    width: 126px;

    .truncated {
      max-width: 89px;
    }

    @media (max-width: 767px) {
      // includes ahref's padding-right
      max-width: 121px;

      .truncated {
        max-width: 85px;
      }
    }
  }
}

.hasTeacherComments {
  background-color: $annoEditNav;
  border-radius: 50% 50%;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: auto;
}

.container-secondarynav .topNav-dropDownRight {
  align-items: center;
  max-width: unset;
}

.brand.brand {
  height: 100%;
  color: $White;
  background: $DBQ;
  text-decoration: none;
  font-family: serif;
  font-size: 135%;
  margin: 0 0;
  padding: 0 15px;
  letter-spacing: initial;
  column-gap: 0.30em;

  &:hover,
  &:focus {
    background: $Red;
    color: $White;
  }
}

.topNav nav .uk-navbar-nav li a.da-portal {
  height: 100%;
  color: $White;
  background: $DBQ;
  border-left: 1px solid $White;
  text-decoration: none;
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: normal;
  margin: 0;
  padding: 0 15px;
  letter-spacing: initial;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &:hover,
  &:focus {
    background: $Red;
    color: $White;
  }

  div {
    font-weight: normal;
    font-style: italic;
    align-self: flex-end;
    font-size: 12px;
    margin-left: 15px;
  }
}

.container-secondarynav .secondarynav-dropDown,
.topNav-dropDown,
.topNav-dropDownRight {
  display: flex;
  align-items: center;
}

.container-secondarynav .secondarynav-dropDown.secondarynav-assignments {
  word-wrap: break-word;
  line-height: 1em;

}

.container-secondarynav .secondarynav-dropDown.secondarynav-assignments.annotation-dropDownToggle {
  width: auto;
}

.container-secondarynav .topNav-dropDownRight {
  color: $White;

  &:hover {
    background: $wcagAAA-GreyDark;
    color: $White;
  }
}

.topNav-dropDownRight {
  align-self: flex-end;

  + .topNav-dropDownRight,
  &:last-of-type + .logout,
  &:last-of-type + .right-side {
    margin-left: 0;
  }
}

.secondarynav-dropDownCenter.secondarynav-dropDownCenter {
  @extend .secondarynav-dropDown;
  margin: 0 auto;
  align-self: center;

  + .side-nav {
    margin-left: 0;
  }
}

//  Secondary Nav
.container-secondarynav.container-secondarynav {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  flex-basis: 100%;
  height: 40px;
  background: $wcagAAA-GreyDark;
  color: $White;

  i {
    margin-left: 5px;
    flex-basis: 10px;
  }

  span {
    display: flex;
    flex-wrap: wrap;
    // overflow: hidden;
    flex-basis: 100%;
    align-content: center;

  }

  dbq-dropdown {
    align-self: flex-start;
    margin-left: 0;

    &[active] .dropTitle,
    .dropTitle:hover {
      color: $Text;
      background-color: $ActiveDDNav;
      text-decoration: none;

      svg.icon {
        fill: $Text;
      }
    }

    &.secondarynav-dropDown-right {
      align-self: flex-end;
      margin-left: auto;
    }

    &.secondarynav-assignments {
      .dropTitle {
        margin-right: 0;
      }
    }
  }

  .dropTitle {
    color: $White;

    &.audios-baselineFix {
      line-height: 0;
      padding-right: 5px;
    }
  }

  > a {
    display: flex;
    padding: 0 10px;
    color: $White;
    max-width: 100%;
    align-items: center;
    // box-sizing: content-box;

    .secondarynav-assignments & {
      width: calc(100% - 20px);
      max-width: unset;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
      color: rgba($White, .3);
      cursor: default;
    }
  }

  a.secondarynav-done.secondarynav-done {
    border: 3px soild $White;
    display: flex;
  }
}

a.side-nav {
  flex-basis: 30px;
  width: 24px;
  padding: 0 !important;
}

.container-secondarynav > a,
.side-nav a {
  color: rgba($White, 0.6);
  font-family: sans-serif;
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;


  &:hover {
    color: $Text;
  }

  &.text-link {
    svg.icon {
      height: 16px;
      min-height: 16px;
      width: 16px;
      min-width: 16px;
      fill: $White;

      @media (min-width: 641px) and (max-width: 961px) {
        margin-right: 5px;
      }
    }

    &:hover,
    &:focus {
      svg.icon {
        fill: $Text;
      }
    }
  }
}

.secondarynav-text-link {
  svg.icon {
    height: 12px;
    min-height: 12px;
    width: 12px;
    min-width: 12px;
    fill: $White;

    @media (min-width: 641px) and (max-width: 961px) {
      margin-right: 5px;
    }
  }

  &:hover,
  &:focus {
    svg.icon {
      fill: $White;
    }
  }
}

.right-side {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  justify-self: end;

  //margin-left: auto;
  //text-indent: -3px;

  height: 100%;
  padding-left: 0 !important;

  span {
    flex-basis: unset !important;
  }
}

.right-side,
.left-side {
  .fontawesome {
    font-size: 20px;
    margin-top: -4px;
  }
}

.side-nav.disabled,
.side-nav.disabled:hover {
  color: rgba(255, 255, 255, 0.3) !important;
  pointer-events: none;
  cursor: default;
  width: 24px;
  height: 40px;
}

a.switchAudio-link.switchAudio-link.switchAudio-link {
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  color: $Text;

  &:hover {
    color: $White;
  }
}

ul.dropMenu {
  // curently only used in the audio dropdown
  list-style: none;
  padding: 0;
  margin: 0;
}

a.manageReturn,
a.manageReturn-alt {
  background-color: #0057ad;
  color: $White;
  text-transform: capitalize;

  &:hover {
    color: $Text;
  }
}

a.manageReturn-alt {
  background-color: $annoEditNav;
}

@media (max-width: 1370px) {

  .essaybuilderNav .dropTitle span {
    max-width: 85px;
    overflow: hidden;

  }
}

.manage-return {
  display: inline-flex;
  height: 100%;
  color: #fff;
  align-items: center;
  margin-left: auto;
  position: relative;
  font-size: 14px;
  line-height: 40px;
  padding: 0 10px !important;
  background-color: $annoEditNav !important;

  svg {
    fill: $White;
    height: 16px;
    width: 16px;
  }

  &:hover,
  &:focus {
    color: $annoNav !important;
    background-color: $White !important;

    svg {
      fill: $annoNav;
    }
  }
}

// english/spanish secondary nav toggle
dbq-lang-modal {
  a.lang-toggle {
    border: 1px solid $DisabledTab;
    color: $White;
    font-size: 14px;
    line-height: 21px;
    text-decoration: none;
    display: flex;
    margin-top: 7px;
    margin-left: 7px;
    padding: 2px 6px;

    &:hover,
    &:focus {
      color: $Text;
      border: 1px solid $White;
      background: $White;
    }
  }
}

nav sw-teacher-student-toggle .uk-navbar-nav > li > a.user-toggle {
  border: 1px solid $DisabledTab;
  color: $White;
  font-size: 14px;
  line-height: 18px !important;
  text-decoration: none;
  display: flex;
  margin-top: 7px;
  padding: 0 6px !important;
  column-gap: unset;

  &:hover,
  &:focus,
  &.active,
  &[active] {
    color: $Text;
    border: 1px solid $White;
    background-color: $White;
  }

  &.teacher-side {
    border-right: 0;
  }

  span {
    font-size: 14px;
    display: inline-block !important;
  }
}

.nav-font-size {
  font-size: 14px;
  line-height: 20px;
}

.word-space-left {
  margin-left: 5px;

  .brand & {
    margin-left: 10px;
  }
}

.word-space-right {
  margin-right: 5px;
}

.skip-link {
  position: absolute;
  top: -50px;
  left: -50px;
  z-index: 0;
}

.uk-navbar-nav > li > a {
  min-height: unset;
  height: 50px;
  text-transform: none;
  letter-spacing: initial;
}

.uk-nav-navbar > li > a:hover,
.uk-nav-navbar > li > a:focus {
  background: $u10;
}

.uk-navbar-left,
.uk-navbar-right {
  align-items: unset;
}

.dbq-navbar-left-with-center {
  margin-right: auto;
}

.dbq-navbar-center,
.dbq-navbar-w100 {
  margin-left: auto;
  margin-right: auto;
  position: relative;

  & + .margin-left-auto {
    margin-left: 0;
  }
}

.dbq-navbar-w100 {
  width: 100%;
}

.uk-navbar-toggle-icon {
  width: 20px;
  height: 20px;
}

// Full width navbar to group all nav items in one element
.dbq-navbar-fullwidth {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  .uk-navbar-nav {
    width: 100%;
  }
}

.margin-right-auto,
.margin-x-auto {
  margin-right: auto;
}

.margin-left-auto,
.margin-x-auto {
  margin-left: auto;

  & + .margin-left-auto {
    margin-left: 0;
  }
}
