/* uk-overwrites.scss */

html {
  // resetting uikit v3's line-height
  line-height: 20px;
}

.uk-panel-title {
  font-weight: bold;
}

a, button,
input, textarea, .ql-editor,
[aria-expanded], [aria-haspopup],
[role="option"], [role="button"],
.sw-annotation-shape, .sw-annotation-arrow,
.sw-annotation-line, .sw-annotation-options {
  &:focus {
    outline: 3px dotted $wcagAAA-dashBBlue !important;
    outline-offset: 3px;
  }
}
.sw-annotation-text.sticky,
.sw-annotation-text.textbox,
.sw-annotation-text.feedback,
.sw-annotation-sticker {
  &:focus-within {
    outline: 3px dotted $wcagAAA-dashBBlue !important;
    outline-offset: 3px;
  }
}

#topNav {
  nav[aria-label="Primary"] {
    a, button,
    [aria-expanded], [aria-haspopup],
    [role="option"], [role="button"] {
      &:focus {
        outline: 3px dotted $Content-Input-BG !important;
        outline-offset: 3px;
      }
    }
  }
  nav#seconNav {
    a, button,
    [aria-expanded], [aria-haspopup],
    [role="option"], [role="button"] {
      &:focus {
        outline: 3px dotted $Black !important;
        outline-offset: 3px;
      }
    }
  }
}

.uk-offcanvas {
  z-index: 6000;

  .uk-nav-offcanvas li a.active,
  .uk-nav-offcanvas li a.active:hover,
  .uk-nav-offcanvas li a.active:focus {
    color: $White;
    outline: none;
  }

  .uk-nav-header {
    padding: 0;
    color: rgba($White, .75) !important;
  }

  .uk-offcanvas-bar .non-link {
    color: $White;
    pointer-events: none;

    em, i,
    b, strong {
      display: contents;
    }
  }
}

.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

em {
  color: $Text;
}

a {
  color: $u10;
}

a.Definition {
  color: $Black;
}

// Definition SVG icons
.flowing-layout {
  a.Definition {
    display: inline-flex;
    border-radius: 50%;

    svg {
      width: 15px;
      height: 15px;
      fill: $DashBBlue;
    }

    &:hover {
      svg {
        fill: $DashBBlueHover;
      }
    }

    &:focus {
      outline: 2px dotted #333;
      outline-offset: 1px;
    }
  }

  b + a.Definition {
    margin-left: 2px;
  }

  img[data-src] {
    &:focus {
      outline: 2px dotted #333;
      outline-offset: 1px;
    }
  }
}

.allcaps {
  text-transform: uppercase;
}

.smalltext {
  font-size: 12px;
}


.uk-text-danger,
.uk-alert-danger {
  color: $DBQ !important;
}

.text-color-reset {
  color: $Text;
}

/* Forms */
// .uk-form-file input[type=file] {
//   position: absolute;
//   top: 0;
//   z-index: 1;
//   width: 100%;
//   opacity: 0;
//   cursor: pointer;
//   left: 0;
// }
// .uk-form-file {
//   vertical-align: middle;
//   position: relative;
//   overflow: hidden;
// }

.almostThere {
  color: $Green;
}

/* Tables */
.uk-table {
  thead th {
    color: $Text;
  }
}

.uk-subnav-pill > * > a:focus,
.uk-subnav-pill > * > a:hover {
  background-color: $GreyLite;
}

.uk-subnav-pill > .uk-active > a {
  color: $Text;
  background-color: $ActiveDDNav;
}

.table-tabs {
  th {
    font-weight: normal;
    font-size: 11px;
    background: $ActiveDDNav;
  }

  tbody tr {
    border-bottom: 1px solid $Disabled;
  }

  tbody tr:last-child {
    border-bottom: 0 solid $Disabled;
  }

  tbody td,
  tfoot td {
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
  }

  tfoot {
    border-top: 1px solid $Disabled;
  }

  tfoot tr {
    font-style: normal;
  }

  tbody tr.accessPrimary td {
    background: rgba($StickyGreen, 0.15);
  }
}

@media only screen and (max-width: 768px) {
  .uk-modal-dialog {
    margin: 50px auto;
  }
}

.uk-label {
  display: inline-block;
  padding: 3px 10px 2px;
  background: $DashBBlue;
  line-height: 1.5;
  font-size: 12px;
  color: $White;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 0;
  text-transform: uppercase;
}

.uk-label-warning {
  background-color: $LabelWarning;
  color: $Text;
}

/* Sticky */
.sticky {
  position: sticky;
  top: 50px;
  left: 0;
  z-index: 100;
}

.responsive-overflow-auto {
  @media only screen and (max-width: $breakpoint-small) {
    overflow: auto;

    .uk-table th.sticky {
      position: unset;
    }
  }
}

.search-results {
  .uk-table th.sticky {
    z-index: 95;
  }
}

.highlighted {
  background-color: yellow;
}

/* Hidden on XS */
@media only screen and (max-width: 540px) {
  .uk-hidden-extraSM {
    display: none !important;
  }
}

// Flex order
@media only screen and (min-width: 768px) {
  .uk-flex-first\@s {
    order: -1;
  }
  .uk-flex-last\@s {
    order: 99;
  }
}

//  error pages
.min-height\@m {
  @media (min-width: $breakpoint-medium) {
    height: 600px;
  }
}

.layers-dropdown {
  // width: 215px;
  // left: -148px !important; // Moves drop down to the LEFT to fix off-screen issue

  .annotation-toggleOn,
  .annotation-toggleOff {
    padding: 6px 0;
  }
}

/* browser-update.org overwrites for spacing */
#buorg.buorg {
  background-color: $DashLtBlue;
  border-top: 1px solid $GreyLite;
  border-bottom: 1px solid $GreyLite;
  position: fixed;
  top: 50px;
}

.buorg-pad {
  padding: 30px 80px !important;
  line-height: 1.2 !important;
}

.buorg-buttons {
  margin: 30px 15px 0 !important;
}

.buorg-icon {
  display: none !important;
}

.buorg-dismiss {
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 33%;
  right: 5%;
}

// v3 uk-overwrites
.uk-width-1-10 {
  width: 10%;
}

.uk-width-2-10 {
  width: 20%;
}

.uk-width-3-10 {
  width: 30%;
}

.uk-width-4-10 {
  width: 40%;
}

.uk-width-5-10 {
  width: 50%;
}

.uk-width-6-10 {
  width: 60%;
}

.uk-width-7-10 {
  width: 70%;
}

.uk-width-8-10 {
  width: 80%;
}

.uk-width-9-10 {
  width: 90%;
}

.uk-width-10-10 {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .dbq-hidden-small, .dbq-visible-large {
    display: none;
  }
  .dbq-visible-small {
    display: inline-block;
  }
}

@media only screen and (min-width: 768px) {
  .dbq-visible-small {
    display: none;
  }
}

@media only screen and (min-width: 960px) {
  .dbq-hidden-large {
    display: none;
  }
}

.dbq-visible-except\@m {
  @media only screen and (min-width: 960px) and (max-width: 1199px) {
    display: none;
  }
}

@media only screen and (max-width: 999px) {
  .dbq-hidden-except\@l {
    display: none;
  }
}


.uk-button {
  text-transform: none !important;

  &.uk-button-default {
    border-color: $Disabled;

    &:hover,
    &:focus {
      border-color: $ActiveDDNav;
    }

    &:active {
      border-color: $GreyLite;
    }
  }
}

// hack for missing line-height due to compile issue (on stg)
.uk-button:not(.uk-button-large):not(.uk-button-small):not(.uk-button-mini):not(.EVAssignment),
dbq-tabs nav .uk-button {
  line-height: 30px;
}

.uk-card-default {
  box-shadow: unset;
}

.uk-badge {
  min-height: 18px;

  &.uk-badge-success {
    background-color: $DashBGreen;
  }

  &.uk-badge-warning {
    background-color: $BadgeWarning;
  }
}

.djhj-notification {
  border-radius: 0 !important;
}

.uk-margin-xsmall-right {
  margin-right: 5px;
}

// Search
.landing-search {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  background-color: $StickyBlue; /* Used if the image is unavailable */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (min-width: 768px) and (max-width: 990px) {
    height: 300px;
  }
  @media only screen and (max-width: 767px) {
    height: 200px;
  }

  .banner-search {
    width: 75%;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    form {
      width: calc(100% - 30px);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .uk-form-icon {
        // should match height of input
        width: 55px;

        svg {
          width: 22px;
          height: 22px;
        }

        &:hover {
          svg, svg use.icon-white {
            color: $button-primary-hover-color;
            fill: $button-primary-hover-color;
          }
        }
      }
    }
  }
}

.nav-search {
  width: 100%;
  height: 50px;
  margin-top: -10px;
  background-color: $DashBBlue;
  box-sizing: border-box;

  .main-content {
    height: 100%;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
  }

  form {
    width: 40%;

    .uk-input {
      width: 100%;
    }
  }
}

.landing-search,
.nav-search {
  button[type="submit"] {
    margin-top: 0 !important;
    border-color: $White;
  }

  form {
    .uk-input {
      border-color: #fff;
    }
  }
}

.search-results {
  .uk-button-link {
    color: $DashBBlue;

    div {
      display: inline-block;
      transition: all 0.2s ease;
      transform: rotate(-90deg);
    }


    &:hover {
      color: $button-primary-hover-color;
      background-color: $DashBBlueHover;
    }

    &[aria-expanded="true"] {
      div {
        transform: rotate(0deg);
      }
    }
  }

  .checkbox-filter-title {
    margin-top: 4px;
    margin-bottom: 0;
  }

  .checkbox-buttons {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    label {
      display: block;
      margin: 0 0 4px 0;
      cursor: pointer;

      input {
        position: absolute;
        display: none;
      }

      span {
        display: block;
        padding: 2px 4px;
        color: $DashBBlue;
        border: 1px solid $DashBBlue;
        text-align: center;
      }

      &:hover, &focus {
        span {
          color: $Black;
          border-color: $Black;
        }
      }

      input:checked + span {
        color: $White;
        border-color: $Black;
        background-color: $Black;
      }
    }
  }

  tbody {
    tr.parent {
      border-top: 1px solid $Disabled;
      border-bottom: 0 solid $Disabled;
    }

    tr.parent:last-child,
    tr.child {
      border-bottom: 0 solid $Disabled;
    }

    tr.child {
      td {
        padding-bottom: 0;

        .content-text {

        }
      }
    }

    tr.child:last-child {
      td {
        padding-bottom: 30px;
      }
    }

    tr:nth-child(2) {
      td {
        border-top: 1px solid $DashBBlue;
      }

      td:first-child {
        border-top: 1px solid $White;
        padding-top: 5px !important;
      }
    }
  }

}

.small-text-in-h4 {
  display: block;
  margin-top: 0;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #999;
}

.img-min-width {
  min-width: 45px;
}

.uk-alert-primary {
  color: $Text;
  border: 5px solid $DashBBlueHover;
  background-color: lighten($DashLtBlue, 2%);
}

.student-dashboard {
  h1 {
    font-size: 21px;
    line-height: normal;
    font-family: 'Helvetica', sans-serif;
    margin: 25px 0;
    font-weight: bold;
    text-align: left;
  }

  h2 {
    font-size: 20px;
    margin: 0 0 10px 0;
    text-align: left;
    font-weight: bold;
    font-family: 'Helvetica', sans-serif;
  }

  h3 {
    font-size: 19px;
    margin: 0 0 10px 0;
    font-family: 'Times', serif;
  }
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0,0,0,0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
