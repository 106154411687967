/* colors.scss */

$White: #fff;
$Black: #000;
$Text: #444;
$MobileNav: #333;
$ActiveDDNav: #f5f5f5;
$Disabled: #eee;
$DisabledTab: #bbb;
$GreyLite: #ddd;
$GreyDark: #797979;
$annoNav: #424242;
$annoEditNav: #F0AD00;
$feedbackNav: #0092B3;
$ebNavAlt: #482d83;
$ebNav: #311169;
$ebPortal: #5638b6;

//Force Scss to preserve the rgba format
$annNavRed: #{'rgb(255, 102, 102)'};
$annNavOrange: #{'rgb(255, 153, 51)'};
$annNavYellow: #{'rgb(255, 255, 51)'};
$annNavGreen: #{'rgb(102, 204, 51)'};
$annNavBlue: #{'rgb(102, 204, 255)'};
$annNavPurple: #{'rgb(204, 153, 255)'};
$annNavGray: #{'rgb(153, 153, 153)'};
$annNavBlack: #{'rgb(0, 0, 0)'};
$annNavWhite: #{'rgb(255, 255, 255)'};

//Force Scss to preserve the rgba format
$editorRed: $annNavRed;
$editorOrange: $annNavOrange;
$editorYellow: $annNavYellow;
$editorGreen: $annNavGreen;
$editorBlue: $annNavBlue;
$editorPurple: $annNavPurple;
$editorGray: $annNavGray;
$editorBlack: $annNavBlack;
$editorWhite: $annNavWhite;

$DBQ: #d41e12;
$u10: $ebNav;

$Red: rgba(255,0,0,1.0); /* HEX #ff0000; */
$Orange: rgba(255,165,0,1.0); /* HEX #ffa500; */
$Yellow: rgba(255,255,0,1.0); /* HEX #ffff00; */
$Green: rgba(0,128,0,1.0); /* HEX #008000; */
$Blue: rgba(0,0,255,1.0); /* HEX #0000ff; */
$Purple: rgba(128,0,128,1.0); /* HEX #800080; */

$StickyRed: rgba(253,160,165,1);
$StickyOrange: rgba(255,210,145,1);
$StickyYellow: rgba(255,255,180,1);
$StickyGreen: rgba(180,255,185,1);
$StickyBlue: rgba(180,220,255,1);
$StickyPurple: rgba(245,175,255,1);

$LabelWarning: #f6d328;
$BadgeWarning: #faa732;

$BoxShadow: #212121;

$DashBRed: #d2211f;
$DashBOrange: #f45f34;
$DashBBlue: #1daeef;
$DashMdBlue: #b3edff;
$DashLtBlue: #CCF3FF;
$DashBBlueHover: #35b3ee;
$DashBGreen: #8cc14c;
$DashBPurple: #b025a9;

// WCAG AAA compliant colors
// see vendor-components.scss for more
// WCAG AAA compliant colors
$wcagAAA-GreyDark: #717171;

$wcagAAA-dashBGreen: #3d8036;
$DashBGreen: $wcagAAA-dashBGreen;
$wcagAAA-dashBGreen-hover: #8cc14c;

$wcagAAA-dashBBlue: #0057ad;
$DashBBlue: $wcagAAA-dashBBlue;
$button-primary-hover-background: #35b3ee;
$DashBBlueHover: $button-primary-hover-background;

$wcagAAA-annoEditNav: #B36200;
$annoEditNav: $wcagAAA-annoEditNav;

$button-primary-hover-color: #1c1c1c;

// Binders
$BinderCivics: #0d4883;
$BinderFlorida: #fe8635;
$BinderLiterature: #6d9a8f;
$BinderTexas: #795344;
$BinderUS: #921621;
$BinderUS-DBQ: #0e1319;
$BinderWorld: $ebNavAlt;
$BinderWorld-DBQ: #064b38;
$BinderGeography: #b6d9e9;
$BinderEconomics: #b08844;
$BinderCalifornia: #fff678;
$BinderGeorgia: #d71635;
$BinderElementary: #bbaad2;
$BinderLouisiana1: #f8dd21;
$BinderLouisiana2: #06b085;

$ukPrimaryHover: $DashBBlue;
$TabActiveHover: $DashBGreen;

$HRText: #f4f4f4;

$Content-Text: #231f20;
$Content-Input-BG: #cccccc;
$Content-Input-Border: #f9f9f9;

$FadedBlue: #f0fbfe;
$FadedRed: #fdf0ed;

$chatActive: #c5e0a5;
$chatViewMode: #f7d680;
$chatInactive: #f39898;
$comment-instructor: #e9e6f5;
$comment-fellow: $Disabled;
$comment-self: #def2fa;

$ui-v3-blue: $wcagAAA-dashBBlue;
$ui-input-border: #e5e5e5;
$ui-input-text: #666;

$CustomBinderRed: #b44137;
$CustomBinderOrange: #d5995a;
$CustomBinderYellow: #fffe85;
$CustomBinderGreen: #94b769;
$CustomBinderTeal: #7ad1cc;
$CustomBinderBlue: #7ea6e8;
$CustomBinderPurple: #9c7ad1;
