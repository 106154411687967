/* aside-accordions.scss */

.accordion-doc-analysis {
  margin-bottom: 0;

  & > li {
    border-bottom: 1px solid $DisabledTab;
    margin-top: 0 !important;

    &:last-child {
      border-bottom: 0;
    }

    .uk-accordion-title {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      padding: 20px 20px 20px 10px;

      &::before {
        margin-left: 0;
        margin-right: 10px;
        float: left;
        min-width: 1.4em;
        min-height: 1.4em;
      }

      &::after {
        content: "";
        width: 18px;
        min-width: 18px;
        height: 18px;
        min-height: 18px;
        margin-left: auto;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGQ9Ik0yNTYsMjNDMTI3LjUsMjMsMjMsMTI3LjUsMjMsMjU2UzEyNy41LDQ4OC45LDI1Niw0ODguOVM0ODguOSwzODQuNSw0ODguOSwyNTZTMzg0LjUsMjMsMjU2LDIzeiBNMjU2LDQwLjkKCWMxMTguOSwwLDIxNS4xLDk2LjIsMjE1LjEsMjE1LjFTMzc0LjksNDcxLjEsMjU2LDQ3MS4xUzQwLjksMzc0LjksNDAuOSwyNTZDNDEsMTM3LjEsMTM3LjEsNDEsMjU2LDQwLjl6Ii8+Cjwvc3ZnPgo=');
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }

    .uk-accordion-content {
      margin-top: 0;
      margin-bottom: 20px;
      padding: 0 20px;

      ul {
        // DBQ-1570
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      li {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }

        .questions {
          font-size: unset;
          font-family: unset;
          color: unset;
          // DBQ-1570 indent the list items
          display: inline-block;
          text-indent: -18px;
        }
      }
    }

    .documentForm-group {
      display: block;
      box-sizing: border-box;
      padding: 0 0 0 20px;

      .question-textbox-hidden {
        display: inline-block;
        margin-bottom: 20px;
      }

      input,
      textarea,
      select {
        width: 100%;
        display: inline-block;

        margin-bottom: 20px;

        &:first-child {
          margin-bottom: 0;
        }

        &[disabled] {
          color: $ui-input-text;
          -webkit-text-fill-color: $ui-input-text;
          opacity: 1;
          /* required on iOS */
        }
      }

      .documentForm-group-title,
      .documentForm-group-subtitle,
      .documentForm-group-text,
      .documentForm-group-repeated {
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 10px;
      }
      .documentForm-group-title,
      .documentForm-group-subtitle {
        font-weight: bold;
      }
      .documentForm-group-repeated {
        margin-bottom: 20px;
        padding: 10px;
        border: 1px solid $Disabled;
        border-radius: 5px;
        background-color: $ActiveDDNav;

        textarea {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &.completed,
    &[completed] {
      .uk-accordion-title {
        &::after {
          background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO30KCS5zdDF7ZmlsbDojOENDMTRDO30KPC9zdHlsZT4KPHJlY3QgY2xhc3M9InN0MCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+CjxnPgoJPHBhdGggY2xhc3M9InN0MSIgZD0iTTExLDE3LjhsLTQuOS00LjlsMS44LTEuOGwzLjEsMy4xbDguNy04LjdDMTcuOCwzLjQsMTUuMSwyLDEyLDJDNi41LDIsMiw2LjUsMiwxMnM0LjUsMTAsMTAsMTBzMTAtNC41LDEwLTEwCgkJYzAtMS41LTAuNC0zLTEtNC4zTDExLDE3Ljh6Ii8+CjwvZz4KPC9zdmc+Cg==');
        }
      }
    }

    .btn-completed {
      pointer-events: none;
      color: $White;
      background-color: $DashBGreen;

      svg {
        width: 14px;
        height: 14px;
        fill: $White;
      }
    }

    .aBucket-add {
      margin-top: -18px;
      margin-bottom: 0;
    }
    .bucket-input-delete {
      @extend .uk-button;
    }
    .aBucket-add,
    .bucket-input-delete {
      background-color: transparent !important;

      &:hover,
      &:focus {
        color: $DashBBlueHover;
        background-color: transparent;
      }
    }
    .aBucket-add,
    .bucket-input-delete {
      font-size: 14px;
      line-height: 30px;
      font-weight: 600;
      color: $DashBBlue;
    }

    hr {
      border-top: 3px dotted $DisabledTab;
    }
  }
}
